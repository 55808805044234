import { AddUserInputType } from '../../gql';

export class RetrieveUser {
  static readonly type = '[USER] retrieve';
  constructor(public tenantKey: number) { }
}

export class RegisterUser {
  static readonly type = '[USER] register';

  constructor(public payload: AddUserInputType) { }
}
