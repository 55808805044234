import { Component, OnDestroy } from '@angular/core';
import { NotificationService } from '@core/services';
import { NgFor, AsyncPipe } from '@angular/common';
import { NotificationModule, NotificationService as appkitNotificationService } from '@appkit4/angular-components/notification';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-encapsulated-banner',
  templateUrl: './encapsulated-banner.component.html',
  styleUrls: ['./encapsulated-banner.component.scss'],
  standalone: true,
  imports: [NgFor, AsyncPipe, NotificationModule],
})
export class EncapsulatedBannerComponent implements OnDestroy {
  status: 'default' | 'success' | 'warning' | 'error' | string = 'default';

  subscription: Subscription;
  constructor(public notificationService: NotificationService,
    protected _notificationSvc: appkitNotificationService) {
      this.subscription = this.notificationService.notifications$.subscribe((next: {type: string, message: string})=> {
        this.openNotification(next.type, next.message);
      })
    }

    ngOnDestroy() {
      if(this.subscription) this.subscription.unsubscribe();
    }

  openNotification(type = 'success', message) {
    this.status = type;
    let title = 'Record Saved';
    let duration = 0;
    let icon = 'icon-success-fill';
    switch (type) {
      case 'warning':
        title = 'Warning';
        icon = 'icon-warning-fill';
        break;
      case 'error':
        title = 'Error!';
        icon = 'icon-error-fill';
        break;
      case 'information':
        duration = 5000;
        title = 'Information';
        icon = 'icon-information-fill';
        break;    
      default: 
        duration = 5000;
        break;    
    }
    this.createNotification(title, message, duration, icon);
  }
  
  createNotification(title = 'Notification', message = 'Default Message', duration = 0, icon = 'icon-success-fill', id = 'notification-1'): void {
    const hyperLink = '';
    const hyperLinkHref = '';
    this._notificationSvc
      .show(
        title,
        message,
        hyperLink,
        hyperLinkHref,
        {
            duration: duration,
            id: id,
            clickToClose: false,
            showClose: true,
            icon: icon,
        }
      )
      setTimeout(()=> {
        const domList = document.querySelectorAll('#notification-1');
        domList[domList.length-1].setAttribute('role', 'alert');
      }, 100)
  }
}
