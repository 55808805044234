import { Injectable } from '@angular/core';
import {
  GqlService,
  UserListInput,
  bkAssignmentMgmt,
  ManageBKInput,
  assignTenantsForBK,
  assignTenantsForUser,
  AddUserInputType,
  registerUser,
  fetchUser,
  changeUserRole,
  deleteUser,
  getAssignedTenants,
  getAssignedTenantsForUser,
  getUnassignedTenantsForUser,
  listUsers,
  onboardOrganization,
  resendUserInvite,
  unassignTenantsForUser,
  DuplicateUserCheckInput,
  duplicateUserCheck,
  InviteUserInput,
  inviteUser,
  listAssignedTenants,
  listAssociatedUsers,
  listDeactivatedUsers,
  deactivateUser,
  updateUser,
  UpdateUserInput,
  getEmailPreferenceTypes,
  getEmailPreferences,
  updateEmailPreferences,
  getUserActions,
  getActionTypes,
  getTenantsForLeaderRole,
  getTenantsForSpecialRole,
  bulkReassignLeaderRole,
  getAssignedTenantsForBK,
  getUserInfo,
  reassignAndDeleteUser,
  reassignAndDeleteCustomer,
  UserActionQueryInput,
  listLoggedUsers,
  listPermissions,
  deleteAvatar,
} from '@core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly gqlService: GqlService) {}

  assignBKsForTenant(manageBKInput: ManageBKInput) {
    return this.gqlService.mutate(bkAssignmentMgmt, { manageBKInput });
  }

  getAssignedTenant(tenantKey: number) {
    return this.gqlService.query(getAssignedTenants, {
      tenantKey,
      skipBkUsers: false,
    });
  }

  assignTenantsForBK(input) {
    return this.gqlService.mutate(assignTenantsForBK, { input });
  }

  getAssignedTenantsForBK(userKey) {
    return this.gqlService.query(getAssignedTenantsForBK, { userKey });
  }

  assignTenantsForUser(input) {
    return this.gqlService.mutate(assignTenantsForUser, { input });
  }

  getAssignedTenantsForUser(input) {
    return this.gqlService.query(getAssignedTenantsForUser, { input });
  }

  getUnassignedTenantsForUser(userKey) {
    return this.gqlService.query(getUnassignedTenantsForUser, { userKey });
  }

  unassignTenantsForUser(userKey, tenantKeys) {
    return this.gqlService.mutate(unassignTenantsForUser, {
      userKey,
      tenantKeys,
    });
  }

  getTenantsForLeaderRole(userKey) {
    return this.gqlService.query(getTenantsForLeaderRole, { userKey });
  }

  getTenantsForSpecialRole(input) {
    return this.gqlService.query(getTenantsForSpecialRole, { input });
  }

  bulkReassignLeaderRole(input) {
    return this.gqlService.mutate(bulkReassignLeaderRole, { input });
  }

  reassignAndDeleteUser(input) {
    return this.gqlService.mutate(reassignAndDeleteUser, { input });
  }

  reassignAndDeleteCustomer(input) {
    return this.gqlService.mutate(reassignAndDeleteCustomer, { input });
  }

  getUserInfo(userKey, tenantKey?) {
    return this.gqlService.query(getUserInfo, {
      userKey,
      tenantKey,
    });
  }

  registerUser(input: AddUserInputType) {
    const registerUserInput = {
      ...input,
      product: environment.iam.clientID,
    };
    return this.gqlService.mutate(registerUser, { registerUserInput });
  }

  fetchUser(fetchUserInput) {
    return this.gqlService.query(fetchUser, { fetchUserInput });
  }

  changeUserRole(input) {
    const { email, role, businessFunction, tenantKey } = input;
    const changeUserRoleInput = {
      email,
      organizationId: 'fakeOrgId',
      role,
      requestingUserEmail: 'fake.email@xx.com',
      product: environment.iam.clientID,
      tenantKey,
      businessFunction,
    };
    return this.gqlService.mutate(changeUserRole, { changeUserRoleInput });
  }

  onboardOrganization(input) {
    return this.gqlService.mutate(onboardOrganization, { input });
  }

  resendUserInvite(input) {
    input.organizationId = 'fakeOrgId';
    return this.gqlService.mutate(resendUserInvite, { input });
  }

  deleteUser(input) {
    input.organizationId = 'fakeOrgId';
    return this.gqlService.mutate(deleteUser, { input });
  }

  deactivateUser(userKey: number) {
    return this.gqlService.mutate(deactivateUser, { userKey });
  }

  // get bookkeepers
  listBookkeeperUsers(
    userListInput: UserListInput,
    skipAssignedTenants = true,
  ) {
    return this.gqlService.query(listUsers, {
      listUsersInput: { ...userListInput },
      skipAssignedTenants,
    });
  }

  // get users from the BK's associated tenants
  listCustomerUsers(userListInput: UserListInput) {
    return this.gqlService.query(listAssociatedUsers, {
      listUsersInput: { ...userListInput },
    });
  }

  listDeactivatedUsers(userListInput: UserListInput) {
    return this.gqlService.query(listDeactivatedUsers, {
      listUsersInput: { ...userListInput },
    });
  }

  // get users from a specific tenant
  listUsers(input) {
    return this.gqlService.query(listUsers, { listUsersInput: { ...input } });
  }

  duplicateUserCheck(duplicateUserCheckInput: DuplicateUserCheckInput) {
    return this.gqlService.query(duplicateUserCheck, {
      duplicateUserCheckInput,
    });
  }

  inviteUser(input) {
    const inviteUserInput: InviteUserInput = {
      organizationId: 'fakeOrgId',
      product: environment.iam.clientID,
      users: input.users,
      tenantKey: input.tenantKey,
    };
    return this.gqlService.mutate(inviteUser, { inviteUserInput });
  }

  listTenants() {
    return this.gqlService.query(listAssignedTenants);
  }

  updateUser(input: UpdateUserInput) {
    const updateUserInput = {
      organizationId: 'fakeOrgId',
      product: environment.iam.clientID,
      ...input,
    };
    return this.gqlService.mutate(updateUser, { updateUserInput });
  }

  updateAvatar(input) {
    const updateUserInput = {
      organizationId: 'fakeOrgId',
      product: environment.iam.clientID,
      ...input,
    };
    return this.gqlService.mutate(
      updateUser,
      { updateUserInput },
      {
        useMultipart: true,
      },
    );
  }

  deleteAvatar() {
    return this.gqlService.mutate(deleteAvatar, {});
  }

  getEmailPreferenceTypes() {
    return this.gqlService.query(getEmailPreferenceTypes);
  }

  getEmailPreferences(input) {
    return this.gqlService.query(getEmailPreferences, { input });
  }

  listPermissions() {
    return this.gqlService.query(listPermissions);
  }

  updateEmailPreferences(input) {
    return this.gqlService.mutate(updateEmailPreferences, { input });
  }

  getUserActions(input: UserActionQueryInput) {
    return this.gqlService.query(getUserActions, { input });
  }

  getActionTypes() {
    return this.gqlService.query(getActionTypes);
  }

  getLoggedUsers(tenantKey?: number) {
    return this.gqlService.query(listLoggedUsers, {
      tenantKeys: tenantKey ? [tenantKey] : undefined,
      isBk: !tenantKey,
    });
  }
}
