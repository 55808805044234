import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Tenant, User } from '@models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { plainToClass } from 'class-transformer';
import { getUser, registerUser } from '../../gql';
import { GqlService } from '../../services';
import { RegisterUser, RetrieveUser } from './user.action';

export interface UserStateModel {
  user: User;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
  },
})
@Injectable()
export class UserState {
  constructor(private readonly gqlService: GqlService) {}

  @Selector()
  static getAuthUser(state: UserStateModel): User {
    /* we put this in here because of browser storage when we
    retrieve we want it to become a user object again */
    return plainToClass(User, state.user);
  }

  @Selector()
  static getAuthUserTenant(state: UserStateModel): Tenant {
    return state.user ? plainToClass(Tenant, state.user.tenant) : null;
  }

  @Action(RetrieveUser)
  async retrieveUser(
    { patchState }: StateContext<UserStateModel>,
    { tenantKey }: RetrieveUser,
  ) {
    const user: User = await this.gqlService
      .query(getUser, { tenantKey })
      .toPromise();
    await patchState({ user });
  }

  @Action(RegisterUser)
  async registerUser(
    { patchState }: StateContext<UserStateModel>,
    { payload }: RegisterUser,
  ) {
    const response = await this.gqlService
      .mutate(registerUser, {
        registerUserInput: { product: environment.iam.clientID, ...payload },
      })
      .toPromise();
    await patchState({ user: response.user });
  }
}
