<ap-header [type]="'transparent'" [compact]="false" (onClickLogo)="navigateToMainPage()">
  <ng-template ngTemplate="title">
    <div class="flex-normal">
      <div>Bookkeeping Connect</div>
      <ng-container *ngIf="showTenantName">
        <div class="ap-header-subtitle-divider"></div>
        <div class="app-text-heading text-wrap-ellipsis " ap-tooltip [direction]="'top'" [tooltipId]="'tenantName'"
          [tooltipContent]="tenantByRoute?.nme | decodeString" ariaDescribedby="'Tenant Name'">{{tenantByRoute?.nme |
          decodeString}}</div>
      </ng-container>
    </div>
  </ng-template>
  <ng-container *ngIf="authUser$ | async as user">
    <ng-template ngTemplate="options">
      <div ap-tooltip [tooltipId]="'appNotifications'" ariaDescribedby="App Notifications"
        [tooltipContent]="'Notifications'" [direction]="'bottom'">
        <ap-header-options-item [iconName]="'notification-outline'" (onClick)="toggleNotificationPanel()"
          [styleClass]="notificationsService.unreadCount > 0 ? 'required-dot-right' : ''">
        </ap-header-options-item>
      </div>
    </ng-template>
    <ng-template ngTemplate="user">
      <div class="flex-normal">
        <app-cus-avatar class="navbar-avatar" [imageSrc]="user?.avatar" [ariaLabel]="'User Profile ' + user.name"
          [name]="user.abbr" [withDropdown]="true" borderWidth="0" diameter="40" [role]="'button'" [list]="navList"
          [attr.data-pendo-id]="avatarPendoId">
          <ng-template ngTemplate="dropdownTemp">
            <div class="ap-option-item avatar-dropdown-user-info flex-gap-3">
              <app-cus-avatar [name]="user.abbr" [imageSrc]="user?.avatar" [withDropdown]="false" [disabled]="true"
                borderWidth="0" diameter="40" [role]="'button'" tabindex="-1"></app-cus-avatar>
              <div class="user-info">
                <div class="text-wrap-ellipsis" title="{{ user.name }}">{{ user.name }}</div>
                <div class="text-wrap-ellipsis app-text-light" title="{{user?.email}}">{{ user?.email }}</div>
              </div>
            </div>
            <ng-container *ngFor="let item of navList; let i = index;">
              <ap-dropdown-list-item #dropdownListItem [item]="item" (onSelectItem)="onSelectItem($event)"
                *ngxPermissionsOnly="item.permission || null" [role]="'list item'" [attr.aria-label]="item.label"
                [attr.data-pendo-id]="item.value === 'User_Management' ? userManagementPendoId : null">
                <ng-template *ngIf="item.iconName" ngTemplate="prefixTemp">
                  <span class="Appkit4-icon icon-{{item.iconName}}"></span>
                </ng-template>
                <ng-template *ngIf="item.descValue" ngTemplate="suffixTemp">
                  <span>{{item.descValue}}</span>
                </ng-template>
              </ap-dropdown-list-item>
            </ng-container>
          </ng-template>
        </app-cus-avatar>
        <div class="navbar-topbar-user-wrap">
          <span class="navbar-topbar-user-tenant text-ellipsis" title="{{user.tenant?.nme || 'No Tenant'}}">
            {{user.tenant?.nme || 'No Tenant'}}
          </span>
          <span class="navbar-topbar-user-name">{{ user.name }}</span>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ap-header>
