<div class="flex-normal full-height pre-login-container">
  <div class="left-part flex-normal flex-direction-column flex-justify-between">
    <div class="login-logo"></div>
    <div class="main-content-ontainer flex-normal flex-1 flex-justify-center max-height-overflow">
      <div [class.position-container]="showPositionContainer">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer class="login-footer"></app-footer>
  </div>
  <div class="right-part">
    <div class="login-wrapper"></div>
  </div>
</div>
